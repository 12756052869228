import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import "./App.css";

const HomePageMobile = loadable(() => import("./pages/Home/HomePageMobile"));
const ServiceListPageMobile = loadable(() =>
  import("./pages/ServiceList/ServiceListPageMobile")
);
const PriceConfig2PageMobile = loadable(() =>
  import(
    "./pages/ServiceConfigurationPage/PriceConfig-2/PriceConfig2PageMobile"
  )
);
const PriceConfig1PageMobile = loadable(() =>
  import(
    "./pages/ServiceConfigurationPage/PriceConfig-1/PriceConfig1PageMobile"
  )
);
const PriceConfig2v2PageMobile = loadable(() =>
  import(
    "./pages/ServiceConfigurationPage/PriceConfig-2/PriceConfig2v2PageMobile"
  )
);
const CheckoutPaymentPageMobile = loadable(() =>
  import("./pages/Checkoutpages/CheckoutPaymentPage/CheckoutPaymentPageMobile")
);
const CheckputReviewPageMobile = loadable(() =>
  import("./pages/Checkoutpages/CheckoutReviewPage/CheckoutReviewPageMobile")
);
const CreateAccountPageMobile = loadable(() =>
  import("./pages/AccountPage/CreateAccount/CreateAccountPageMobile")
);
const SignupPageMobile = loadable(() =>
  import("./pages/AccountPage/Signup/SignupPageMobile")
);
const LoginPageMobile = loadable(() =>
  import("./pages/AccountPage/LoginPage/LoginPageMobile")
);
const AboutYou = loadable(() =>
  import("./pages/TellUsAbout/TellUsAboutPageMobile")
);
const MyHomeServicesPageMobile = loadable(() =>
  import("./pages/MyHomeServices/MyHomeServicesPageMobile")
);
const PaymentSavedCards = loadable(() =>
  import("./pages/Payments/PaymentCardsPage/PaymentCardsPageMobile")
);
const PaymentHistoryPageMobile = loadable(() =>
  import("./pages/Payments/PaymentHistory/PaymentHistoryPageMobile")
);
const MyProfilePageMobile = loadable(() =>
  import("./pages/MyProfile/MyProfilePageMobile")
);
const CustomerSupport = loadable(() =>
  import("./pages/CustomerSupportPage/CustomerSupportPageMobile")
);
const MyAddresses = loadable(() =>
  import("./pages/AddressesPage/MyAddressesPageMobile")
);
const SelectAddressPageMobile = loadable(() =>
  import("./pages/AddressesPage/SelectAddressPageMobile")
);
const MyServicesPageMobile = loadable(() =>
  import("./pages/MyServices/MyServicesPageMobile")
);
const InseptionReportPageMobile = loadable(() =>
  import("./pages/InspectionReport/InspectionReportPageMobile")
);
const ResetPassword = loadable(() =>
  import("./pages/AccountPage/resetPassword/ResetPassword")
);
const VerifyResetPassword = loadable(() =>
  import("./pages/VerifyResetPassword/VerifyResetPassword")
);
const ServicePlans = loadable(() =>
  import("./pages/servicePlans/ServicePlans")
);
const RoofTypesPage = loadable(() => import("./pages/RoofTypes/RoofTypes"));
const ErrorPage = loadable(() => import("./pages/ErrorPage/ErrorPage"));

const App = () => {
  useEffect(() => {
    if (
      window.location.host !== "localhost:5001" &&
      window.location.host !== "20.121.64.153"
    ) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "nxibzvnxfn");
    }
  }, []);
  return (
    <div className="mx-auto">
      <Switch>
        <Route exact path="/" component={HomePageMobile} />
        <Route exact path="/book-service" component={ServiceListPageMobile} />
        <Route
          exact
          path="/customize/step-1"
          component={PriceConfig1PageMobile}
        />
        <Route
          exact
          path="/customize/step-1/Mosquito"
          component={PriceConfig2v2PageMobile}
        />
        <Route
          exact
          path="/customize/step-2"
          component={PriceConfig2PageMobile}
        />
        <Route
          exact
          path="/checkout/payment"
          component={CheckoutPaymentPageMobile}
        />
        <Route
          exact
          path="/checkout/review"
          component={CheckputReviewPageMobile}
        />
        <Route
          exact
          path="/my-home-services"
          component={MyHomeServicesPageMobile}
        />
        <Route
          exact
          path="/create/account"
          component={CreateAccountPageMobile}
        />
        <Route exact path="/signup" component={SignupPageMobile} />
        <Route exact path="/login" component={LoginPageMobile} />
        <Route exact path="/forgot-password" component={ResetPassword} />
        <Route
          exact
          path="/verify-reset-password"
          component={VerifyResetPassword}
        />
        <Route exact path="/tell-us-about-you" component={AboutYou} />
        <Route exact path="/payment/savedcards" component={PaymentSavedCards} />
        <Route
          exact
          path="/payment/history"
          component={PaymentHistoryPageMobile}
        />
        <Route exact path="/my-profile" component={MyProfilePageMobile} />
        <Route exact path="/customer-support" component={CustomerSupport} />
        <Route exact path="/address/my" component={MyAddresses} />
        <Route
          exact
          path="/address/select"
          component={SelectAddressPageMobile}
        />
        <Route exact path="/my-services" component={MyServicesPageMobile} />
        <Route
          exact
          path="/inspection-report"
          component={InseptionReportPageMobile}
        />
        <Route exact path="/services/service-plans" component={ServicePlans} />
        <Route exact path="/services/roof-types" component={RoofTypesPage} />
        <Route exact path="/error-page" component={ErrorPage} />
      </Switch>
    </div>
  );
};

export default App;
